<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Create Password</b-card-title>

    <!-- Alert -->
    <b-alert variant="info" dismissible show fade>
      Seem like you are a first time user, enter a password to register this
      mobile number and continue
    </b-alert>

    <register
      v-if="mobile"
      :mobile="mobile"
      @success="successRegisterRequest"
    />
  </div>
</template>

<script>
import Register from "../../../../../components/auth/Register";

export default {
  components: { Register },
  props: ["store"],
  created() {
    if (!this.mobile) {
      // no mobile no. found in route params
      this.$router.push({ name: "Cart" });
      return;
    }
  },
  data() {
    return {
      routeParams: this.$route.params,
    };
  },
  computed: {
    mobile() {
      return this.routeParams.mobile;
    },
  },
  methods: {
    successRegisterRequest(data) {
      // redirect to verify OTP
      this.$router.push({
        name: "CartVerifyOtp",
        params: { otpData: { ...data } },
      });
    },
  },
};
</script>
